// Generated by Framer (f7d95e4)

import { addFonts, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import * as sharedStyle from "../css/XdYKavQM_";

const enabledGestures = {P8x9x8Eed: {hover: true, pressed: true}};

const serializationHash = "framer-rBp66"

const variantClassNames = {P8x9x8Eed: "framer-v-imbrxb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "P8x9x8Eed", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://www.linkedin.com/company/applifting-io/"} motionChild nodeId={"P8x9x8Eed"} scopeId={"bKtK2vJqD"}><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-imbrxb", className, classNames)} framer-16he6ug`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"P8x9x8Eed"} ref={refBinding} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.12)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 36, borderBottomRightRadius: 36, borderTopLeftRadius: 36, borderTopRightRadius: 36, ...style}} variants={{"P8x9x8Eed-hover": {"--border-color": "rgba(255, 255, 255, 0.28)"}, "P8x9x8Eed-pressed": {"--border-color": "var(--token-52b9f1ec-8a5c-4a68-9b06-4560bba78883, rgb(255, 255, 255))"}}} {...addPropertyOverrides({"P8x9x8Eed-hover": {"data-framer-name": undefined}, "P8x9x8Eed-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-18zh1ui"} data-styles-preset={"XdYKavQM_"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-52b9f1ec-8a5c-4a68-9b06-4560bba78883, rgb(255, 255, 255)))"}}>LinkedIn</motion.p></React.Fragment>} className={"framer-12tzseo"} data-framer-name={"label"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"HTAwDq64j"} style={{"--extracted-r6o4lv": "var(--token-52b9f1ec-8a5c-4a68-9b06-4560bba78883, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rBp66.framer-16he6ug, .framer-rBp66 .framer-16he6ug { display: block; }", ".framer-rBp66.framer-imbrxb { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 8px 20px 8px 20px; position: relative; text-decoration: none; width: min-content; }", ".framer-rBp66 .framer-12tzseo { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rBp66.framer-imbrxb { gap: 0px; } .framer-rBp66.framer-imbrxb > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-rBp66.framer-imbrxb > :first-child { margin-left: 0px; } .framer-rBp66.framer-imbrxb > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ".framer-rBp66[data-border=\"true\"]::after, .framer-rBp66 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 101.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"fTfsuB2vh":{"layout":["auto","auto"]},"K5C2PL7Bk":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbKtK2vJqD: React.ComponentType<Props> = withCSS(Component, css, "framer-rBp66") as typeof Component;
export default FramerbKtK2vJqD;

FramerbKtK2vJqD.displayName = "chip-linkedin";

FramerbKtK2vJqD.defaultProps = {height: 40, width: 101.5};

addFonts(FramerbKtK2vJqD, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})